import axios from 'axios'
import store from "@/store/index";
import router from "@/routers";

export default {

    async SetReport({ commit }, report) {
        commit("setReport", report);
    },

    async LogOut({ commit }) {
        commit("logout", null);
    },

    async LogIn({ commit }, User) {
        await axios.post('/flow/login', User)
            .then(function (response) {
                commit('setUser', { ...response.data.data.health_professional_info, token: response.data.data.token })
            })
            .catch(error => {
                console.error(error);
                throw error
            });
    },

    async GetSpecialtys() {
        let user = store.state.user;
        try {
            let response = await axios.post('/specialty/company',
                {
                    'company_id': user.companies_info[0].id
                }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status == 200) {
                return response.data.data.specialties_info;
            }
        } catch (error) {
            console.error(error);
            throw error
        }
    },

    async RegisterComplete(_, body) {
        try {
            let response = await axios.post('/health_professional/register-with-validation', body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status == 200) {
                return { "status": 200, "message": "success" };
            }
        } catch (error) {
            console.error("Erro ao registrar o usuario", error);
            return { "status": error.response.status, "error": error.response };
        }
    },

    async ValidatePin(_, info) {
        try {
            let response = await axios.post('/flow/check-pin', info, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status == 200) {
                return response.data.data
            }
            else {
                return null;
            }
        } catch (error) {
            console.error("Erro ao validar o cpf", error);
            return null;
        }
    },

    async GeneratePin(_, phone) {
        await axios.post('/flow/generate-pin', {
            "phone": "55" + phone
        })
            .then(function (response) {
                if (response.status == 200) {
                    return true
                }
                else {
                    return false
                }
            })
            .catch(error => {
                console.error(error);
                throw error
            });
    },

    async GetFormAssistence() {
        let specialty = store.state.specialty
        try {
            const response = await axios.post('/resource/protocol_update_form', {
                "specialty_id": specialty.id
            });

            if (response.status === 200) {
                return response.data.data.protocol_update_form_info;
            } else {
                return null;
            }

        } catch (error) {
            console.error(error);
            throw error;
        }
    }, 
    
    async UploadFile(_, file) {
        let fileFin = file.file;
        if (!(fileFin instanceof Blob)) {
            fileFin = new Blob([file.file], { type: file.type || 'application/octet-stream' });
        }
        const fileName = file.name || 'arquivo';
        if (!(fileFin instanceof File)) {
            fileFin = new File([fileFin], fileName, { type: file.type || 'application/octet-stream' });
        }    
        const user = store.state.user;
        const formData = new FormData();
        formData.append('file', fileFin);    
        try {
            const response = await axios.post('/file/upload', formData, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });    
            if (response.status === 200) {
                return response.data.data;
            } else {                
                return null;
            }
        } catch (error) {
            console.error('Erro no upload:', error);
            return null;
        }
    },

    async CreatePin(_, phone) {
        await axios.post('/flow/create-pin', {
            "phone": "55" + phone
        })
            .then(function (response) {
                if (response.status == 200) {
                    return true
                }
                else {
                    return false
                }
            })
            .catch(error => {
                console.error(error);
                throw error
            });
    },

    async ValidationDocumentNotToken(_, patientData) {
        let body = {
            "number": patientData.cpf.replace(/[.-]/g, ''),
            "birthdate": patientData.birthDate
        };
        try {
            let response = await axios.post('/validation/cpf', body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status == 200) {
                return response.data.data.person_info;
            }
            else {
                return null;
            }
        } catch (error) {
            console.error("Erro ao validar o cpf", error);
            return null;
        }
    },

    async ValidationDocument(_, patientData) {
        let user = store.state.user
        let body = {
            "number": patientData.cpf.replace(/[.-]/g, ''),
            "birthdate": patientData.birthDate
        };
        try {
            let response = await axios.post('/validation/cpf', body, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status == 200) {
                return response.data.data.person_info;
            }
            else {
                return null;
            }
        } catch (error) {
            console.error("Erro ao validar o cpf", error);
            return null;
        }
    },

    async CreateProtocol(_, body) {
        let user = store.state.user
        try {
            let response = await axios.post('/protocol_update/create',
                body,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.status
        } catch (error) {
            console.error("Erro ao criar o protocolo", error);
            return error.response.status
        }
    },

    async RegisterResponsible({ commit }, body) {
        let user = store.state.user
        try {
            let response = await axios.post('/responsible/register',
                body,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            commit("setResponsible", response.data.data.health_professional_info);
        } catch (error) {
            console.error("Erro ao registrar o responsável", error);
        }
    },

    async GetResponsibleCFM(_, body) {
        let user = store.state.user
        try {
            let response = await axios.post('/search/cfm', body, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status == 200) {
                return response.data.data.health_professional_info
            }
            else {
                return null
            }
        } catch (error) {
            console.error("Erro ao obter as informações de CFM", error);
            return null
        }
    },

    async GetPatientById({ commit }, patientId) {
        let user = store.state.user
        try {
            let response = await axios.post('/patient/get', { patient_id: patientId }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            commit("setPatient", response.data.data.patient_info);
        } catch (error) {
            console.error("Erro ao obter as informações do paciente", error);
        }
    },

    async RegisterPatient({ commit }, patientInfo) {
        let user = store.state.user
        try {
            let response = await axios.post('/patient/register', patientInfo, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                }
            });
            commit("setPatient", response.data.data.patient_info);
        } catch (error) {
            console.error("Erro ao registrar o paciente", error);
            throw error
        }
    },

    async GetChildrens({ commit }, patient) {
        let user = store.state.user
        let body = {
            "patient_id": patient.id
        };
        try {
            let response = await axios.post("/patient/children", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setChildren", response.data.data.patients_info);
        } catch (error) {
            console.error("Erro ao buscar o paciente:", error);
        }
    },

    async GetPatient({ commit }, document) {
        let user = store.state.user
        let body = {
            "document_number": document.number.replace(/[.-]/g, ''),
            "document_type": document.type
        };
        try {
            let response = await axios.post("/patient/document", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setPatient", response.data.data.patient_info);
        } catch (error) {
            console.error("Erro ao buscar o paciente:", error);
        }
    },

    async GetEvolutionCases({ commit }) {
        let user = store.state.user
        let specialty = store.state.specialty
        let body = {
            "company_code": user.companies_info[0].code,
            "outcome_type_ids": [4],
            "specialty_id": specialty.id,
            "not_updated": 0
        };
        try {
            let response = await axios.post("/protocol/list", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setEvolutionCases", response.data.data.protocols_info);
        } catch (error) {
            console.error("Erro ao buscar os casos em evolução:", error);
        }
    },

    async GetDailyCases({ commit }) {
        let user = store.state.user
        let specialty = store.state.specialty
        let body = {
            "company_code": user.companies_info[0].code,
            "outcome_type_ids": [4],
            "specialty_id": specialty.id,
            "not_updated": 1
        };
        try {
            let response = await axios.post("/protocol/list", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setDailyCases", response.data.data.protocols_info);
        } catch (error) {
            console.error("Erro ao buscar os casos diários:", error);
        }
    },

    async GetEvolvedPatientsToday({ commit }) {
        let user = store.state.user
        let specialty = store.state.specialty
        let body = {
            "company_code": user.companies_info[0].code,
            "specialty_id": specialty.id,
        };
        try {
            let response = await axios.post("/protocol_update/daily", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setEvolvedPatientsToday", response.data.data.protocol_updates_info);
        } catch (error) {
            console.error("Erro ao buscar os casos diários:", error);
        }
    },

    async GetDailyGeneralCases({ commit }) {
        let user = store.state.user
        let specialty = store.state.specialty
        let body = {
            "company_code": user.companies_info[0].code,
            "outcome_type_ids": null,
            "specialty_id": specialty.id,
            "not_updated": 0
        };
        try {
            let response = await axios.post("/protocol/list", body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setDailyGeneralCases", response.data.data.protocols_info);
        } catch (error) {
            console.error("Erro ao buscar os casos diários:", error);
        }
    },

    async GetOutcomeResource({ commit }) {
        let user = store.state.user
        try {
            let response = await axios.get("/resource/outcome", {}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            commit("setOutcome", response.data.data.outcomes_info);
        } catch (error) {
            console.error("Erro ao buscar o recurso de desfecho:", error);
        }
    },

    async ValidKeyInstitute(_, keyInsitute) {
        let body = {
            "key": keyInsitute
        };
        try {
            let response = await axios.post('/company/key', body,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data.data.company_info
        } catch (error) {
            console.error("Erro ao buscar company:", error);
        }
    },

    async CloseProtocol(_, patientData) {
        let user = store.state.user
        let body = {
            "protocol_id": patientData.id,
            "outcome_type_id": patientData.outcome,
            "overwrite": false
        };
        try {
            let response = await axios.post('/protocol/close', body,
                {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.status
        } catch (error) {
            return error.response.status
        }
    },

    async GetHistorics({ commit }, params) {
        let user = store.state.user
        let resultados = null;
        await axios.post('/flow/report/list',
            params,
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                resultados = response.data.data;
            })
            .catch(error => {
                resultados = null
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    this.$router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
        return resultados;
    },

    async GetReportById({ commit }, id) {
        let user = store.state.user
        if (id != null) {
            await axios.post('/flow/report/get',
                {
                    report_id: id
                },
                {
                    headers: { Authorization: `Bearer ${user.token}` }
                })
                .then(response => {
                    commit("setReport", response.data.data.report_info);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        commit("logout", null);
                        router.push('/login');
                    } else {
                        console.error(error);
                        throw error
                    }
                });
        }
    },


    async GetReport({ commit }, id) {
        let user = store.state.user
        if (id != null) {
            await axios.post('/flow/report/get',
                {
                    report_id: id
                },
                {
                    headers: { Authorization: `Bearer ${user.token}` }
                })
                .then(response => {
                    commit("setReport", response.data.data.report_info);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        commit("logout", null);
                        router.push('/login');
                    } else {
                        console.error(error);
                        throw error
                    }
                });
        }
    },

    async GenerateReport({ commit }, report) {
        let user = store.state.user
        await axios.post('/flow/report/get_url',
            {
                report_id: report.id
            },
            {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then(response => {
                commit("setUrlReport", response.data.data.url);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit("logout", null);
                    router.push('/login');
                } else {
                    console.error(error);
                    throw error
                }
            });
    },

}
